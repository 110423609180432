/* eslint-disable vue/one-component-per-file */

import { CellClassParams, CellRendererSelectorResult, ColDef } from 'ag-grid-enterprise';
import Decimal from 'decimal.js';
import Vue, { SetupFunction, defineComponent } from 'vue';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ComponentProxy = defineComponent<{
  params: {
    component: {
      props: string[];
      setup: SetupFunction<Record<string, unknown>>;
    };
    props: Record<string, unknown>;
  };
}>({
  render(h) {
    const cmp = Vue.extend(this.params.component);
    return h(cmp, { props: this.params.props });
  },
});

export function component<Props extends Record<string, unknown>>(
  setup: SetupFunction<Props>,
  components?: Record<string, unknown>
) {
  return (props: Props | undefined): undefined | CellRendererSelectorResult => {
    return !props
      ? undefined
      : {
          component: 'ComponentProxy',
          params: {
            props,
            component: {
              props: Object.fromEntries(
                Object.keys(props).map((key) => [key, { default: undefined }])
              ),
              setup,
              components,
            },
          },
        };
  };
}

export function alignLeft(): Pick<ColDef, 'cellClass' | 'suppressHeaderMenuButton'> {
  return {
    cellClass(params) {
      const extraPadding = params.colDef.suppressHeaderMenuButton ? 0 : 4;
      return `align-center justify-start pl-${extraPadding + 4}`;
    },
  };
}

export function alignRight(config?: {
  hasPostfix: boolean;
}): Pick<ColDef, 'headerClass' | 'cellClass' | 'suppressHeaderMenuButton'> {
  return {
    headerClass(params) {
      const extraPadding = (params as CellClassParams<unknown, unknown>).colDef
        .suppressHeaderMenuButton
        ? 0
        : 4;
      return `header-right pr-${extraPadding + 6}`;
    },
    cellClass(params) {
      const extraPadding = params.colDef.suppressHeaderMenuButton ? 0 : 4;
      return `align-center justify-end pr-${extraPadding + (config?.hasPostfix ? 4 : 6)}`;
    },
  };
}

export function alignCenter(): Pick<ColDef, 'headerClass' | 'cellClass'> {
  return {
    headerClass: 'header-center',
    cellClass: 'align-center justify-center',
  };
}

function cellClassToString<Params>(
  cellClass:
    | undefined
    | string
    | string[]
    | ((params: Params) => null | undefined | string | string[]),
  params: Params
) {
  const result = typeof cellClass === 'function' ? cellClass(params) : cellClass;
  return Array.isArray(result) ? result.join(' ') : result ?? '';
}

export function mergeClasses(
  classes: Array<Pick<ColDef, 'headerClass' | 'cellClass'>>
): Pick<ColDef, 'headerClass' | 'cellClass'> {
  return {
    headerClass: (params) =>
      classes.map(({ headerClass }) => cellClassToString(headerClass, params)),
    cellClass: (params) => classes.map(({ cellClass }) => cellClassToString(cellClass, params)),
  };
}

export function alignColumn(config: {
  align: 'left' | 'right' | 'center';
  hasPostfix?: boolean;
}): Pick<ColDef, 'headerClass' | 'cellClass'> {
  config.hasPostfix = config.hasPostfix ?? false;
  switch (config.align) {
    case 'left':
      return alignLeft();
    case 'right':
      return alignRight({ hasPostfix: config.hasPostfix });
    case 'center':
      return alignCenter();
  }
}

export const comparator = {
  date(a: Date | number | string | null | undefined, b: Date | number | string | null | undefined) {
    a = +new Date(a ?? NaN);
    b = +new Date(b ?? NaN);
    return comparator.number(isNaN(a) ? Infinity : a, isNaN(b) ? Infinity : b);
  },
  number(a: number | null | undefined, b: number | null | undefined) {
    a ??= Infinity;
    b ??= Infinity;
    return a - b;
  },
  decimal(a: Decimal | null | undefined, b: Decimal | null | undefined) {
    a ??= new Decimal(Infinity);
    b ??= new Decimal(Infinity);
    return a.comparedTo(b);
  },
} as const;

export const equals = {
  decimal(a: Decimal | null | undefined, b: Decimal | null | undefined): boolean {
    return a && b ? a.equals(b) : a === b;
  },
} as const;
