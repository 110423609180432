import { ColDef, ColDefField, ValueFormatterParams } from 'ag-grid-enterprise';
import { alignColumn, alignLeft, alignRight, comparator, component } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import { Benchmark } from '@/utils/api/loans';
import { h } from 'vue';
import FormatSide from '@/modules/common/components/format-side/FormatSide.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { Security } from '@/modules/common/models';

export function prettyPrice(value: Decimal): string {
  return getPriceAsString(value, PRICE_PRECISION);
}

interface ColConfig<T> {
  field: ColDefField<T>;
  headerName: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
}

export function checkbox(): ColDef<unknown> {
  return {
    colId: 'checkbox',
    headerCheckboxSelection: true,
    // it would be great to be able to use
    // headerCheckboxSelectionCurrentPageOnly: true
    // but it's not supported with "serverSide" row model
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    pinned: 'left',
    lockVisible: true,
    sortable: true,
    maxWidth: 64,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    suppressColumnsToolPanel: true,
  };
}

// format number with thousands separator and 2 decimal places aligining right
export function quantity<T>({ field, headerName, align = 'right' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => formatPrettyNumber(params.value),
    comparator: comparator.number,
    // for very speficic cases we want to align left (like top/depth of book views that mirror sides)
    ...alignColumn({ align }),
  };
}

export function price<T>({ field, headerName, align = 'right' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => `$${prettyPrice(params.value)}`,
    comparator: comparator.decimal,
    ...alignColumn({ align }),
  };
}

const timestampAdapter = component<{ date: string; time: string }>(
  (props) => () =>
    h('div', [h('span', props.date), h('span', { class: 'ml-1 font-weight-light' }, props.time)])
);

export function timestamp<T>({ field, headerName, align = 'left' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    cellRendererSelector: (params) =>
      timestampAdapter({
        date: formatDate(params.value, 'MMM dd'),
        time: formatDate(params.value, 'hh:mm a'),
      }),
    comparator: comparator.date,
    ...alignColumn({ align }),
  };
}

export function date<T>({ field, headerName, align = 'left' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, 'MM/dd/yy'),
    comparator: comparator.date,
    ...alignColumn({ align }),
  };
}

export function cusip<T extends { security: Security }>(): ColDef<T> {
  return {
    field: 'security.cusip' as ColDefField<T>,
    colId: 'security.cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

export const rateAdapter = component<{
  rate: null | number | string | Decimal;
  rateModifier: undefined | Benchmark;
  precision: undefined | number;
}>((props) => () => (props.rate === null ? h('span', undefined, '–') : h(RateOutput, { props })));

export function rate<
  T extends { rate: Decimal; rateModifier: undefined | Benchmark },
>(): ColDef<T> {
  return {
    field: 'rate' as ColDefField<T>,
    colId: 'rate',
    headerName: 'Rate',
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
        rateModifier: params.data?.rateModifier,
        precision: undefined,
      }),
    comparator: comparator.decimal,
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export const formatSideAdapter = component<{ side: 'BORROWER' | 'LENDER' }>(
  (props) => () => h(FormatSide, { props })
);

export function side<T extends { side: 'BORROWER' | 'LENDER' }>(): ColDef<T> {
  return {
    field: 'side' as ColDefField<T>,
    colId: 'side',
    headerName: 'Side',
    cellRendererSelector: (params) =>
      formatSideAdapter({
        side: params.value,
      }),
    ...alignLeft(),
  };
}

export function ticker<T extends { security: Security }>(): ColDef<T> {
  return {
    field: 'security.ticker' as ColDefField<T>,
    colId: 'security.ticker',
    headerName: 'Ticker',
    ...alignLeft(),
  };
}

const settlementOptions: Array<{ value: string; text: string }> = [
  { value: 'NSCC', text: 'NSCC' },
  { value: 'BILATERAL', text: 'Bilateral' },
  { value: 'OCC', text: 'OCC' },
];

export function settlementTypeLabel(settlType: string): string {
  const item = settlementOptions.find((i) => i.value === settlType);
  return item ? item.text : '–';
}

const settlementTypeAdapter = component<{ settlType: string }>(
  (props) => () => h('span', settlementTypeLabel(props.settlType))
);

// settlementOptions[props.settlementType].

export function settlementType<T extends { settlementType: string }>(): ColDef<T> {
  return {
    field: 'settlementType' as ColDefField<T>,
    colId: 'settlementType',
    headerName: 'Sett Type',
    cellRendererSelector: (params) =>
      settlementTypeAdapter({
        settlType: params.value,
      }),
    ...alignLeft(),
  };
}
