import { LoanEvent, LoanEventBase, RawLoanEvent } from './loan-event';
import { OpenLoan, RawLoanBuyIn, RawLoanReturn } from './open-loans';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { RawSecurity } from './security';
import { parseISO } from 'date-fns';
import { RawCompanyInfo, RawLoanRecall, RawLoanRenegotiation } from '@/modules/common/models';

export type RawLoanDetailsLoan = Raw<
  LoanDetailsLoan,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    counterparty: RawCompanyInfo;
    renegotiation: RawLoanRenegotiation | null;
    openRecalls: RawLoanRecall[];
    openBuyIns?: RawLoanBuyIn[];
    loanReturns: RawLoanReturn[];
  },
  'security' | 'availableActions'
>;

export class LoanDetailsLoan extends OpenLoan {
  public declare side: 'LENDER' | 'BORROWER';
  public returnPendingSince: Date | null;
  public renegotiatePendingSince: Date | null;
  public pendingDtccRefId: string | null;
  public activeDtccRefId: string | null;
  public canceledReason: string;

  protected constructor(data: RawLoanDetailsLoan) {
    super(data);

    this.returnPendingSince =
      data.returnPendingSince === null ? null : parseISO(data.returnPendingSince);
    this.renegotiatePendingSince =
      data.renegotiatePendingSince === null ? null : parseISO(data.renegotiatePendingSince);
    this.pendingDtccRefId = data.pendingDtccRefId;
    this.activeDtccRefId = data.activeDtccRefId;
    this.canceledReason = data.canceledReason;
  }

  public static fromData(data: RawLoanDetailsLoan): LoanDetailsLoan;
  public static fromData(data: RawLoanDetailsLoan | null): LoanDetailsLoan | null;
  public static fromData(data: RawLoanDetailsLoan | undefined): LoanDetailsLoan | undefined;
  public static fromData(
    data: null | undefined | RawLoanDetailsLoan
  ): null | undefined | LoanDetailsLoan {
    if (data === null) return null;
    if (data === undefined) return undefined;

    return new LoanDetailsLoan(data);
  }

  public static mockData(data?: DeepPartial<RawLoanDetailsLoan>): RawLoanDetailsLoan {
    return {
      ...OpenLoan.mockData(data),
      side: data?.side ?? 'LENDER',
      returnPendingSince: data?.returnPendingSince ?? null,
      renegotiatePendingSince: data?.renegotiatePendingSince ?? null,
      pendingDtccRefId: data?.pendingDtccRefId ?? null,
      activeDtccRefId: data?.activeDtccRefId ?? 'DTCC01',
      canceledReason: data?.canceledReason ?? '',
    };
  }
}

export type RawLoanDetails = Raw<
  LoanDetails,
  {
    // always specify existing raw entry types explititly
    items: RawLoanEvent[];
    summary: RawLoanDetailsLoan;
  },
  'history' | 'loan'
>;

export class LoanDetails {
  public history: LoanEvent[];
  public loan: LoanDetailsLoan | null;

  protected constructor(data: RawLoanDetails) {
    this.history = data.items.map<LoanEvent>(LoanEventBase.fromData);
    this.loan = LoanDetailsLoan.fromData(data.summary);
  }

  public static fromData(data: RawLoanDetails): LoanDetails {
    return new LoanDetails(data);
  }

  public static mock(data?: null | DeepPartial<RawLoanDetails>): LoanDetails {
    return LoanDetails.fromData(LoanDetails.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanDetails>): RawLoanDetails {
    const { items, summary } = data ?? {};

    return {
      items: items?.map<RawLoanEvent>(LoanEventBase.mockData) ?? [],
      summary: LoanDetailsLoan.mockData(summary) ?? null,
    };
  }
}
