<template>
  <span v-if="formattedNumber !== null">{{ formattedNumber }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import formatPrettyNumber from './formatPrettyNumber';

@Component({
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
})
export default class PrettyNumber extends Vue {
  public readonly value!: string | number | null;

  public get formattedNumber(): string | null {
    return this.value === null ? null : formatPrettyNumber(this.value);
  }
}
</script>
